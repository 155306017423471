import Swiper, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

Swiper.use([Navigation, Pagination, Autoplay]);

jQuery(document).ready(function ($) {
	function assign_bootstrap_mode() {
		let width = $(window).width();
		var mode = "";
		if (width < 768) {
			mode = "mode-xs";
		} else if (width < 992) {
			mode = "mode-sm";
		} else if (width < 1200) {
			mode = "mode-md";
		} else if (width > 1200) {
			mode = "mode-lg";
		}
		$("body")
			.removeClass("mode-xs")
			.removeClass("mode-sm")
			.removeClass("mode-md")
			.removeClass("mode-lg")
			.addClass(mode);
	}
	assign_bootstrap_mode();

	$(window).resize(function () {
		assign_bootstrap_mode();
	});

	$(".thumb").addClass("notloaded");
	$(".thumb--noimage").addClass("loaded");

	// Images Loaded
	const imgLoaded = function () {
		$(".media").each(function () {
			if ($(this).find("iframe").length > 0) {
				const iframeWidth = $(this).find("iframe").attr("width");
				const iframeHeight = $(this).find("iframe").attr("height");
				const iframeRatio = iframeHeight / iframeWidth;
				const newHeight = $(this).find("iframe").width() * iframeRatio;
				$(this)
					.find("iframe")
					.height(Math.ceil(newHeight - 1));
				$(this)
					.find("iframe")
					.parent("p")
					.height(Math.ceil(newHeight - 1));
			}
		});

		if (!$("body").hasClass("mode-xs")) {
			var asideHeight = $(".single-aside-content").outerHeight();

			let buttonsHeight = 0;
			if ($(".single-buttons").length >= 1) {
				buttonsHeight = $(".single-buttons").height();
			}
			var textHeight = $(".single-text > .single-text-foil").height();

			if (textHeight + buttonsHeight < asideHeight) {
				$(".single-text > .single-text-foil").css(
					"min-height",
					asideHeight - buttonsHeight
				);
			} else {
				$(".single-text > .single-text-foil").css("min-height", "");
			}
		}
	};
	new imagesLoaded("body", imgLoaded);

	$(window).on("resize", imgLoaded);

	// Grid
	var grid = $(".grid").isotope({
		itemSelector: ".thumb",
		layoutMode: "masonry",
		isInitLayout: true,
		masonry: {
			columnWidth: ".grid-sizer",
		},
	});

	grid.imagesLoaded().progress(function () {
		//grid.isotope('layout');
	});

	grid.imagesLoaded().always(function () {
		grid.isotope("layout");
		$(".loading-anim").hide();
		$(".thumb").addClass("loaded");
	});

	$(document).on("click", ".filter", function (evt) {
		evt.preventDefault();
		var filter = $(this).attr("data-filter");
		$(".filter").removeClass("active-filter");
		$(this).addClass("active-filter");
		grid.isotope({ filter: filter });

		var slug = $(this).attr("data-slug");
		$(".thumb > a").each(function () {
			var href = $(this).attr("href");
			href = href.replace(/\?.*/, "");
			href = href + "?category=" + slug;
			$(this).attr("href", href);
		});
	});

	if (window.location.hash) {
		var filter = window.location.hash.substr(1);
		$('.filter[data-slug="' + filter + '"]').click();
	}

	$(document).on("click", ".menu-filter, .header-link", function (evt) {
		evt.preventDefault();
		var menuFilter = $(this).attr("data-filter");
		menu.isotope({ filter: menuFilter + ", .main-menu-item" });
		grid.isotope({ filter: menuFilter });
	});

	const swiper = new Swiper(".swiper", {
		direction: "horizontal",
		loop: true,
		autoplay: {
			delay: 3000,
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});
});
